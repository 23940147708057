<script>
import UserService from "@/api/services/user.service.js";

export default {
  props: {
    visible: Boolean,
    closeable: Boolean,
    loggedInUser: Object
  },
  data() {
    return {
      /****** Data variables *****/
      skipModal: false,
      /****** Operational Variables *****/
      isLoading: false,
      isError: false
    };
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  methods: {
    async clickCancel() {
      if (this.skipModal) {
        this.isLoading = true;
        try {
          await UserService.skipIngressIntegrationsPrerequisite(
            this.loggedInUser.id
          );
        } catch (error) {
          console.log("error: ", error);
          this.isError = true;
          this.isLoading = false;
        }
      }

      this.$emit("close");
    }
  }
};
</script>

<template>
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
  >
    <div class="row justify-content-center mt-3">
      <div class="col-md-12 text-center">
        <h5>{{ $t("liveImportStep1.ingressPreRequirement.title") }}</h5>
      </div>
    </div>
    <div class="row justify-content-center mt-3" v-if="!this.isLoading">
      <div class="col-md-10 text-center">
        {{ $t("liveImportStep1.ingressPreRequirement.text.part1") }}
        <br />
        <br />
        <a href="https://help.redem.io/docs/data-import/live-import-integrations/keyingress-integration/" target="_blank"
          >{{ $t("liveImportStep1.ingressPreRequirement.text.part2") }}
          <i class="bx bx-link-external ml-1"></i
        ></a>
      </div>
    </div>
    <div class="row justify-content-center mt-4" v-if="!this.isLoading">
      <div class="col-md-12 text-center">
        <b-form-checkbox v-model="skipModal" name="check-button">
          {{ $t("liveImportStep1.ingressPreRequirement.text.skip") }}
        </b-form-checkbox>
        <div class="text-danger" v-if="this.isError">
          {{ $t("liveImportStep1.ingressPreRequirement.error") }}
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-4 mb-3" v-if="!this.isLoading">
      <div class="col-md-12 text-center">
        <b-button variant="primary" @click="clickCancel()"
          >{{ $t("liveImportStep1.ingressPreRequirement.button.readMessage") }}
        </b-button>
      </div>
    </div>

    <!-- Loading -->
    <div class="row justify-content-center mt-3" v-if="this.isLoading">
      <div class="col-6 text-center p-5">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>
  </b-modal>
</template>
