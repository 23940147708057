import { ApiCall } from "../apiBase";

class IntegrationPartnerService {
  /*********** Get All Integration Partners *****/
  async getAllIntegrationPartners() {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getAllIntegrationPartners {
            getAllIntegrationPartners {
              id
		          name
		          host
            }
          }
        `
      }
    });

    if (
      result?.data?.data?.getAllIntegrationPartners &&
      !result?.data?.errors
    ) {
      return result?.data?.data?.getAllIntegrationPartners;
    } else {
      return result?.data?.errors[0]?.extensions?.code;
    }
  }
}

export default new IntegrationPartnerService();
