var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        centered: "",
        "hide-footer": "",
        "hide-header": "",
        "no-close-on-backdrop": "",
      },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c("div", { staticClass: "row justify-content-center mt-2" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("h5", [
            _vm._v(_vm._s(_vm.$t("liveImportStep1.nipoUserAccessModal.title"))),
          ]),
          _vm.isUserLoaded && _vm.isFirstTime
            ? _c("div", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("liveImportStep1.nipoUserAccessModal.description1")
                    ) +
                    " "
                ),
              ])
            : _vm.isUserLoaded && !_vm.isFirstTime
            ? _c("div", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("liveImportStep1.nipoUserAccessModal.description2")
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
          _c("hr"),
        ]),
      ]),
      !this.isLoading
        ? _c("div", { staticClass: "row justify-content-center mt-2 mb-2" }, [
            _c(
              "div",
              { staticClass: "col-12 mb-3" },
              [
                this.isSuccess
                  ? _c("b-alert", { attrs: { show: "", variant: "success" } }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "liveImportStep1.nipoUserAccessModal.form.messages.success"
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                this.isError
                  ? _c(
                      "b-alert",
                      {
                        attrs: { show: "", variant: "danger", dismissible: "" },
                      },
                      [
                        this.errorMessage && this.errorMessage != ""
                          ? _c("div", [
                              _vm._v(" " + _vm._s(_vm.errorMessage) + " "),
                            ])
                          : _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "liveImportStep1.nipoUserAccessModal.form.messages.error"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "form-group mb-3" }, [
                _c("label", { attrs: { for: "domain" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "liveImportStep1.nipoUserAccessModal.form.domain.label"
                        )
                      ) +
                      " "
                  ),
                  _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.domain,
                      expression: "domain",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "form-control",
                  class: { "is-invalid": _vm.$v.domain.$error },
                  attrs: {
                    id: "domain",
                    type: "text",
                    name: "domain",
                    placeholder: _vm.$t(
                      "liveImportStep1.nipoUserAccessModal.form.domain.placeholder"
                    ),
                  },
                  domProps: { value: _vm.domain },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.domain = $event.target.value.trim()
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
                _vm.$v.domain.$error
                  ? _c("div", { staticClass: "invalid-feedback" }, [
                      _vm.$v.domain.$error && !_vm.$v.domain.required
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "liveImportStep1.nipoUserAccessModal.form.domain.requiredMsg"
                                )
                              )
                            ),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "form-group mb-3" }, [
                _c("label", { attrs: { for: "username" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "liveImportStep1.nipoUserAccessModal.form.username.label"
                        )
                      ) +
                      " "
                  ),
                  _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.username,
                      expression: "username",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "form-control",
                  class: { "is-invalid": _vm.$v.username.$error },
                  attrs: {
                    id: "username",
                    type: "text",
                    name: "username",
                    placeholder: _vm.$t(
                      "liveImportStep1.nipoUserAccessModal.form.username.placeholder"
                    ),
                  },
                  domProps: { value: _vm.username },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.username = $event.target.value.trim()
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
                _vm.$v.username.$error
                  ? _c("div", { staticClass: "invalid-feedback" }, [
                      _vm.$v.username.$error && !_vm.$v.username.required
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "liveImportStep1.nipoUserAccessModal.form.username.requiredMsg"
                                )
                              )
                            ),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "form-group mb-3" }, [
                _c("label", { attrs: { for: "password" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "liveImportStep1.nipoUserAccessModal.form.password.label"
                        )
                      ) +
                      " "
                  ),
                  _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.password,
                      expression: "password",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "form-control",
                  class: { "is-invalid": _vm.$v.password.$error },
                  attrs: {
                    id: "password",
                    type: "password",
                    name: "password",
                    placeholder: _vm.$t(
                      "liveImportStep1.nipoUserAccessModal.form.password.placeholder"
                    ),
                  },
                  domProps: { value: _vm.password },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.password = $event.target.value.trim()
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
                _vm.$v.password.$error
                  ? _c("div", { staticClass: "invalid-feedback" }, [
                      _vm.$v.password.$error && !_vm.$v.password.required
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "liveImportStep1.nipoUserAccessModal.form.password.requiredMsg"
                                )
                              )
                            ),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "col-12" }, [
              _c(
                "div",
                { staticClass: "form-group mb-3" },
                [
                  _c("label", { attrs: { for: "region" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "liveImportStep1.nipoUserAccessModal.form.region.label"
                          )
                        ) +
                        " "
                    ),
                    _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                  ]),
                  _c("b-form-select", {
                    attrs: {
                      id: "region",
                      options: _vm.regionsToSelect,
                      placeholder: _vm.$t(
                        "liveImportStep1.nipoUserAccessModal.form.region.placeholder"
                      ),
                    },
                    model: {
                      value: _vm.region,
                      callback: function ($$v) {
                        _vm.region = $$v
                      },
                      expression: "region",
                    },
                  }),
                  _vm.displayRequiredErrorForRegion
                    ? _c("small", { staticClass: "text-danger" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "liveImportStep1.nipoUserAccessModal.form.region.requiredMsg"
                            )
                          )
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
            !this.isSuccess
              ? _c(
                  "div",
                  { staticClass: "col-md-12 mt-4" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "success" },
                        on: {
                          click: function ($event) {
                            return _vm.clickSaveUserPreferenceForNipo()
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "liveImportStep1.nipoUserAccessModal.form.buttons.link"
                            )
                          ) + " "
                        ),
                      ]
                    ),
                    _c(
                      "b-button",
                      {
                        staticClass: "ml-2",
                        attrs: { variant: "light" },
                        on: {
                          click: function ($event) {
                            return _vm.clickCancel()
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "liveImportStep1.nipoUserAccessModal.form.buttons.cancel"
                            )
                          ) + " "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      this.isLoading
        ? _c("div", { staticClass: "row justify-content-center mt-3" }, [
            _c(
              "div",
              { staticClass: "col-6 text-center p-5" },
              [
                _c("b-spinner", {
                  staticClass: "m-2",
                  staticStyle: { width: "5rem", height: "5rem" },
                  attrs: { variant: "mid", role: "status" },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }