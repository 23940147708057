var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        centered: "",
        "hide-footer": "",
        "hide-header": "",
        "no-close-on-backdrop": "",
      },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c("div", { staticClass: "row justify-content-center mt-3" }, [
        _c("div", { staticClass: "col-md-12 text-center" }, [
          _c("h5", [
            _vm._v(
              _vm._s(_vm.$t("liveImportStep1.ingressPreRequirement.title"))
            ),
          ]),
        ]),
      ]),
      !this.isLoading
        ? _c("div", { staticClass: "row justify-content-center mt-3" }, [
            _c("div", { staticClass: "col-md-10 text-center" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("liveImportStep1.ingressPreRequirement.text.part1")
                  ) +
                  " "
              ),
              _c("br"),
              _c("br"),
              _c(
                "a",
                {
                  attrs: {
                    href: "https://help.redem.io/docs/data-import/live-import-integrations/keyingress-integration/",
                    target: "_blank",
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("liveImportStep1.ingressPreRequirement.text.part2")
                    ) + " "
                  ),
                  _c("i", { staticClass: "bx bx-link-external ml-1" }),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      !this.isLoading
        ? _c("div", { staticClass: "row justify-content-center mt-4" }, [
            _c(
              "div",
              { staticClass: "col-md-12 text-center" },
              [
                _c(
                  "b-form-checkbox",
                  {
                    attrs: { name: "check-button" },
                    model: {
                      value: _vm.skipModal,
                      callback: function ($$v) {
                        _vm.skipModal = $$v
                      },
                      expression: "skipModal",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "liveImportStep1.ingressPreRequirement.text.skip"
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
                this.isError
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "liveImportStep1.ingressPreRequirement.error"
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
      !this.isLoading
        ? _c("div", { staticClass: "row justify-content-center mt-4 mb-3" }, [
            _c(
              "div",
              { staticClass: "col-md-12 text-center" },
              [
                _c(
                  "b-button",
                  {
                    attrs: { variant: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.clickCancel()
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "liveImportStep1.ingressPreRequirement.button.readMessage"
                        )
                      ) + " "
                    ),
                  ]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      this.isLoading
        ? _c("div", { staticClass: "row justify-content-center mt-3" }, [
            _c(
              "div",
              { staticClass: "col-6 text-center p-5" },
              [
                _c("b-spinner", {
                  staticClass: "m-2",
                  staticStyle: { width: "5rem", height: "5rem" },
                  attrs: { variant: "mid", role: "status" },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }