<script>
import UserService from "@/api/services/user.service.js";
import AuthService from "@/api/services/auth.service.js";
import { required } from "vuelidate/lib/validators";
import { getErrorMessageByErrorCode } from "@/shared/util/helpers.js";
// import { ERROR_CODES } from "./errorCodes.js";

export default {
  props: {
    visible: Boolean,
    closeable: Boolean
  },
  data() {
    return {
      /****** Data variables *****/
      username: null,
      password: null,
      region: "Europe",
      domain: null,
      regionsToSelect: ["Asia Pacific", "America", "China", "Europe"],
      /****** Operational Variables *****/
      isLoading: false,
      isError: false,
      errorMessage: false,
      isSuccess: false,
      displayRequiredErrorForRegion: false,
      isUserLoaded: false,
      isFirstTime: false
    };
  },
  validations: {
    username: { required },
    domain: { required },
    password: { required }
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  async mounted() {
    let loggedInUser = await AuthService.getLoggedInUser();
    this.isUserLoaded = true;
    this.domain = loggedInUser.userPreferences?.nipoIntegration.domain;
    this.username = loggedInUser.userPreferences?.nipoIntegration.username;
    this.region = loggedInUser.userPreferences?.nipoIntegration.region;
    this.password = loggedInUser.userPreferences?.nipoIntegration.password;
    if (!this.domain) this.isFirstTime = true;
  },
  methods: {
    validateForm() {
      this.$v.$touch();

      // Check region is selected or not
      if (this.region) {
        return !this.$v.$invalid ? true : false;
      } else {
        this.displayRequiredErrorForRegion = true;
      }
    },
    async clickSaveUserPreferenceForNipo() {
      let isValidationSuccess = this.validateForm();
      if (isValidationSuccess) {
        this.isLoading = true;
        let preferences = {
          username: this.username,
          password: this.password,
          domain: this.domain,
          region: this.region
        };

        try {
          const response =
            await UserService.setUserPreferencesForNipoIntegration(preferences);

          if (response === "SUCCESS") {
            this.isSuccess = true;
            this.isError = false;
            this.isLoading = false;

            let self = this;
            setTimeout(function () {
              self.$emit("close");
            }, 2000);
          } else {
            let errorCode = response;
            this.errorMessage = getErrorMessageByErrorCode(errorCode);
            this.isError = true;
            this.isLoading = false;
          }
        } catch (error) {
          console.log("error: ", error);
          this.isError = true;
          this.isLoading = false;
        }
      }
    },
    clickCancel() {
      this.$emit("close");
    }
  }
};
</script>

<template>
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
  >
    <!-- Modal Title and Description-->
    <div class="row justify-content-center mt-2">
      <div class="col-md-12">
        <h5>{{ $t("liveImportStep1.nipoUserAccessModal.title") }}</h5>
        <div v-if="isUserLoaded && isFirstTime">
          {{ $t("liveImportStep1.nipoUserAccessModal.description1") }}
        </div>
        <div v-else-if="isUserLoaded && !isFirstTime">
          {{ $t("liveImportStep1.nipoUserAccessModal.description2") }}
        </div>
        <hr />
      </div>
    </div>

    <!-- Form Component -->
    <div class="row justify-content-center mt-2 mb-2" v-if="!this.isLoading">
      <!-- Messages -->
      <div class="col-12 mb-3">
        <!-- Success Banner -->
        <b-alert show variant="success" v-if="this.isSuccess">
          {{ $t("liveImportStep1.nipoUserAccessModal.form.messages.success") }}
        </b-alert>

        <!-- Error Banner -->
        <b-alert show variant="danger" v-if="this.isError" dismissible>
          <div v-if="this.errorMessage && this.errorMessage != ''">
            {{ errorMessage }}
          </div>
          <div v-else>
            {{ $t("liveImportStep1.nipoUserAccessModal.form.messages.error") }}
          </div>
        </b-alert>
      </div>

      <!-- Doamin -->
      <div class="col-12">
        <div class="form-group mb-3">
          <label for="domain">
            {{ $t("liveImportStep1.nipoUserAccessModal.form.domain.label") }}
            <span class="text-danger">*</span>
          </label>
          <input
            v-model.trim="domain"
            id="domain"
            type="text"
            class="form-control"
            name="domain"
            :placeholder="
              $t('liveImportStep1.nipoUserAccessModal.form.domain.placeholder')
            "
            :class="{ 'is-invalid': $v.domain.$error }"
          />
          <div v-if="$v.domain.$error" class="invalid-feedback">
            <span v-if="$v.domain.$error && !$v.domain.required">{{
              $t("liveImportStep1.nipoUserAccessModal.form.domain.requiredMsg")
            }}</span>
          </div>
        </div>
      </div>      

      <!-- Username -->
      <div class="col-12">
        <div class="form-group mb-3">
          <label for="username">
            {{ $t("liveImportStep1.nipoUserAccessModal.form.username.label") }}
            <span class="text-danger">*</span>
          </label>
          <input
            v-model.trim="username"
            id="username"
            type="text"
            class="form-control"
            name="username"
            :placeholder="
              $t(
                'liveImportStep1.nipoUserAccessModal.form.username.placeholder'
              )
            "
            :class="{ 'is-invalid': $v.username.$error }"
          />
          <div v-if="$v.username.$error" class="invalid-feedback">
            <span v-if="$v.username.$error && !$v.username.required">{{
              $t(
                "liveImportStep1.nipoUserAccessModal.form.username.requiredMsg"
              )
            }}</span>
          </div>
        </div>
      </div>

      <!-- Password -->
      <div class="col-12">
        <div class="form-group mb-3">
          <label for="password">
            {{ $t("liveImportStep1.nipoUserAccessModal.form.password.label") }}
            <span class="text-danger">*</span>
          </label>
          <input
            v-model.trim="password"
            id="password"
            type="password"
            class="form-control"
            name="password"
            :placeholder="
              $t(
                'liveImportStep1.nipoUserAccessModal.form.password.placeholder'
              )
            "
            :class="{ 'is-invalid': $v.password.$error }"
          />
          <div v-if="$v.password.$error" class="invalid-feedback">
            <span v-if="$v.password.$error && !$v.password.required">{{
              $t(
                "liveImportStep1.nipoUserAccessModal.form.password.requiredMsg"
              )
            }}</span>
          </div>
        </div>
      </div>

      <!-- Region -->
      <div class="col-12">
        <div class="form-group mb-3">
          <label for="region">
            {{ $t("liveImportStep1.nipoUserAccessModal.form.region.label") }}
            <span class="text-danger">*</span>
          </label>
          <b-form-select
            id="region"
            v-model="region"
            :options="regionsToSelect"
            :placeholder="
              $t('liveImportStep1.nipoUserAccessModal.form.region.placeholder')
            "
          ></b-form-select>
          <small v-if="displayRequiredErrorForRegion" class="text-danger">{{
            $t("liveImportStep1.nipoUserAccessModal.form.region.requiredMsg")
          }}</small>
        </div>
      </div>

      <!-- Button Row -->
      <div class="col-md-12 mt-4" v-if="!this.isSuccess">
        <b-button variant="success" @click="clickSaveUserPreferenceForNipo()"
          >{{ $t("liveImportStep1.nipoUserAccessModal.form.buttons.link") }}
        </b-button>
        <b-button class="ml-2" variant="light" @click="clickCancel()"
          >{{ $t("liveImportStep1.nipoUserAccessModal.form.buttons.cancel") }}
        </b-button>
      </div>
    </div>

    <!-- Loading -->
    <div class="row justify-content-center mt-3" v-if="this.isLoading">
      <div class="col-6 text-center p-5">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>
  </b-modal>
</template>
